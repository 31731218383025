import { useEffect, useState } from "react";
import { authProvider } from "../authProvider";
import {
    DetailsList,
    DetailsRow,
    Dropdown,
    Label,
    SelectionMode,
    Stack,
    TextField,
} from "@fluentui/react";
import SubscriptionList from "./SubscriptionList";

const apiRoot = window.location.origin;
const ReportList = () => {
    const [token, setToken] = useState("");
    const [reports, setReports] = useState([]);
    const [displayedReports, setDisplayedReports] = useState([]);
    const [locations, setLocations] = useState([]);
    const [selectedLocation, setSelectedLocation] = useState("");
    const [recipients, setRecipients] = useState([]);
    const [selectedRecipient, setSelectedRecipient] = useState("");
    const [reportName, setReportName] = useState("");

    useEffect(() => {
        authProvider.getAccessToken().then((token) => {
            setToken(token.accessToken);
        });
    }, []);

    useEffect(() => {
        if (token) {
            fetch(apiRoot + "/api/devdocs/reports", {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                },
            }).then((response) => {
                if (response.ok) {
                    response.json().then((data) => {
                        var allReports = processReports(data);
                        setReports(allReports);
                        setDisplayedReports(allReports);
                    });
                }
            });
        }
    }, [token]);
    
    useEffect(()=>{
        var filteredItems = [...reports]
        if (selectedLocation) {
            filteredItems = filteredItems.filter((report) => report.location === selectedLocation);
        }
        if (selectedRecipient) {
            filteredItems = filteredItems.filter((report) => {
                if (!report.subscriptions){
                    return;
                }
                return report.subscriptions.some((subscription) => {
                    if (!subscription.recipients){
                        return;
                    }
                    return subscription.recipients.some((recipient) => recipient.recipient === selectedRecipient);
                });
            });
        }
        if (reportName) {
            filteredItems = filteredItems.filter((report) => report.name.toLowerCase().includes(reportName.toLowerCase()));
        }
        setDisplayedReports(filteredItems);
    }, [reports,reportName, selectedLocation, selectedRecipient]);

    const getLocation = (path) => {
        // the path is in this format: /Development Reports/CW All Billable Development Hours we want everything before the last /
        const pathParts = path.split("/");
        return pathParts.slice(0, pathParts.length - 1).join("/");
    };
    const processReports = (data) => {
        // for each report, we want to set location, and other properties
        const reports = data.map((report) => {
            return {
                ...report,
                location: getLocation(report.path),
            };
        });
        // get the unique locations from reports and set them into locations as options for a Dropdown
        const uniqueLocations = [
            { key: "", text: "All" },
            ...[...new Set(
                reports
                    .map((report) => report.location)
                    .filter((location) => location) // Filter out empty locations
            )].sort((a, b) => a.localeCompare(b)).map((location) => ({ key: location, text: location }))
        ];
        
        setLocations(uniqueLocations);

        // get the unique recipients from the report schedules and set them into recipients as options for a Dropdown
        const uniqueRecipients = [
            { key: "", text: "All" },
            ...[...new Set(
                reports.flatMap((report) => {
                    if (!report.subscriptions) return [];
                    return report.subscriptions.flatMap((subscription) => {
                        if (!subscription.recipients) return [];
                        return subscription.recipients.map((recipient) => recipient.recipient);
                    });
                }).filter((recipient) => recipient) // Filter out empty recipients
            )].sort((a, b) => a.localeCompare(b)).map((recipient) => ({ key: recipient, text: recipient }))
        ];
     
        setRecipients(uniqueRecipients);
        setReportName(""); // Reset the reportName filter on reload.
        return reports;
    };

    const reportColumns = [
        {
            key: "name",
            name: "Name",
            fieldName: "name",
            minWidth: 300,
            maxWidth: 500,
            isResizable: true,
        },
        {
            key: "description",
            name: "Description",
            fieldName: "description",
            minWidth: 300,
            onRender: (item) => {
                return (
                    <span
                        style={{
                            whiteSpace: "wrap",
                            overflow: "hidden",
                            textOverflow: "wrap",
                        }}
                    >
                        {item.description}
                    </span>
                );
            }
        }, 
        {
            key: "subscriptions",
            name: "Subscriptions",
            onRender: (item) => {
                return <span>{item.subscriptions.length}</span>;
            },
            minWidth: 100,
            maxWidth: 200,
            isResizable: true,
        },
        {
            key: "path",
            name: "Location",
            fieldName: "location",
            minWidth: 300,
            isResizable: true,
        },
    ];
    const renderRow = (props) => {
        if (!props) {
            return null;
        }

        const { item } = props;

        return (
            <>
                <DetailsRow
                    {...props}
                    styles={{
                        cell: { fontWeight: "bold" }, // Apply bold style to all cells
                    }}
                />
                {item.subscriptions.length > 0 && (
                    <SubscriptionList items={item.subscriptions} />
                )}
            </>
        );
    };
    return (
        <div>
            <h2>Report Server Reports</h2>
            <Stack horizontal tokens={{ childrenGap: 10 }}>
            <Label>Filters</Label>
            <TextField label="Report Name" value={reportName} onChange={(e) => setReportName(e.target.value)} styles={{root: {width: "400px"}}}/>
            <Dropdown options={locations} label="Location" selectedKey={selectedLocation} onChange={(e, item) => setSelectedLocation(item.key)} styles={{root:{width:"400px"}}} />
            <Dropdown options={recipients} label="Recipient" selectedKey={selectedRecipient} onChange={(e, item) => setSelectedRecipient(item.key)}  styles={{root: {width:"400px"}}}/>
            </Stack>
            <DetailsList
                items={displayedReports}
                columns={reportColumns}
                selectionMode={SelectionMode.none}
                compact
                onRenderRow={renderRow}
                styles={{ cell: { fontWeight: "bold" } }}
            />
        </div>
    );
};

export default ReportList;
