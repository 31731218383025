import { DetailsList, SelectionMode } from "@fluentui/react";
const daysOfWeek = ["sunday", "monday", "tuesday", "wednesday", "thursday", "friday", "saturday"];
const shortDays = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
const monthsOfYear = ["january", "february", "march", "april", "may", "june", "july", "august", "september", "october", "november", "december"];
const shortMonths = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
const SubscriptionList = ({ items }) => {
    return (
        <>
        {/* <DetailsList
            styles={{
                root: { marginLeft: "40px", marginTop: "0px", paddingTop: "0" },
                headerWrapper: { paddingTop: "0px" },
                header: { paddingTop: "0px" },
                contentWrapper: {paddingTop: "0px"}
            }}
            items={items}
            columns={[
                {
                    key: "recipients",
                    name: "Recipients",
                    fieldName: "subscriptionName",
                    minWidth: 200,
                    maxWidth: 400,
                    isResizable: true,
                    onRender: (item) => {
                        return (
                            <span
                                style={{
                                    whiteSpace: "wrap",
                                    overflow: "hidden",
                                    textOverflow: "wrap",
                                }}
                            >
                                {formatRecipients(item)}
                            </span>
                        );
                    },
                },
                {
                    key: "subscriptionType",
                    name: "Subscription Type",
                    minWidth: 200,
                    maxWidth: 200,
                    isResizable: true,
                    onRender: (item) => {
                        return (
                            <span
                                style={{
                                    whiteSpace: "nowrap",
                                    overflow: "hidden",
                                    textOverflow: "wrap",
                                }}
                            >
                                {getSubscriptionType(item)}
                            </span>
                        );
                    },
                },
                {
                    key: "startDate",
                    name: "Start Date",
                    minWidth: 200,
                    maxWidth: 200,
                    isResizable: true,
                    onRender: (item) => {
                        return (
                            <span
                                style={{
                                    whiteSpace: "nowrap",
                                    overflow: "hidden",
                                    textOverflow: "wrap",
                                }}
                            >
                                {formatDate(item.schedule.startDateTime)}
                            </span>
                        );
                    },
                },
                {
                    key: "details",
                    name: "Details",
                    minWidth: 200,
                    maxWidth: 200,
                    isResizable: true,
                    onRender: (item) => {
                        return (
                            <span
                                style={{
                                    whiteSpace: "wrap",
                                    overflow: "hidden",
                                    textOverflow: "wrap",
                                }}
                            >
                                {getSubscriptionDetails(item)}
                            </span>
                        );
                    },
                },
                // Add more columns as needed
            ]}
            selectionMode={SelectionMode.none}
            compact
        /> */}
        
        <table style={{width: "calc(100% - 40px)", marginLeft: "40px", marginBottom: "20px"}}>
            <thead>
                <th style={{textAlign: "left"}}>Recipients</th>
                <th style={{textAlign: "left"}}>Schedule Type</th>
                <th style={{textAlign: "left"}}>Start Date</th>
                <th style={{textAlign: "left"}}>Details</th>
            </thead>
            <tbody>
                {items.map((item, index) => (
                    <tr key={`sub_${index}`}>
                        <td>{formatRecipients(item)}</td>
                        <td>{getSubscriptionType(item)}</td>
                        <td>{formatDate(item.schedule.startDateTime)}</td>
                        <td>{getSubscriptionDetails(item)}</td>
                    </tr>
                ))}
            </tbody>
        </table>
        </>
    );
};
function formatRecipients(subscription) {
    if (subscription.recipients) {
        return subscription.recipients.map((r) => r.recipient).join(", ");
    }
    return "Unknown";
}
function getSubscriptionType(subscription) {
    var result = "Unknown";
    if (subscription.schedule && subscription.schedule.recurrence) {
        if (subscription.schedule.recurrence.dailyRecurrence) {
            result = "Daily";
        }
        if (subscription.schedule.recurrence.weeklyRecurrence) {
            result = "Weekly";
        }
        if (subscription.schedule.recurrence.monthlyRecurrence) {
            result = "Monthly";
        }
        if (subscription.schedule.recurrence.monthlyDowRecurrence) {
            result = "Monthly Day of Week";
        }
    }
    return result;
}
function getSubscriptionDetails(subscription) {
    var result = "Unknown";
    if (subscription.schedule && subscription.schedule.recurrence) {
        var recurrence = subscription.schedule.recurrence;
        if (recurrence.dailyRecurrence) {
            result = `Every ${recurrence.dailyRecurrence.daysInterval} days`;
        }
        
        if (recurrence.weeklyRecurrence) {
            
            const days = daysOfWeek
                .filter((day, index) => recurrence.weeklyRecurrence.daysOfWeek[day])
                .map((day, index) => shortDays[daysOfWeek.indexOf(day)]);
        
            const allDays = days.join(", ");
            result = `Every ${recurrence.weeklyRecurrence.weeksInterval} weeks on ${allDays}`;
        }
        if (recurrence.monthlyRecurrence) {
            const months = monthsOfYear
                .filter((month, index) => recurrence.monthlyRecurrence.monthsOfYear[month])
                .map((month, index) => shortMonths[monthsOfYear.indexOf(month)]);   
            const allMonths = months.join(", ");
            result = `Every ${recurrence.monthlyRecurrence.days} months on ${allMonths}`;
            
        }
        if (recurrence.monthlyDowRecurrence) {
            result = "Monthly Day of Week";
        }
        result = `${result} at ${formatTime(subscription.schedule.startDateTime)}`;
    }
    return result;
}
function formatDate(dateString) {
    const date = new Date(dateString);
    return date.toLocaleDateString('en-US', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit'
    });
}
function formatTime(dateString) {
    // Example date string: 2022-09-04T02:00:00
    // Format as 12 hour time with am/pm
    const date = new Date(dateString);
    let hours = date.getHours();
    const minutes = date.getMinutes();
    const ampm = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    const minutesStr = minutes < 10 ? '0' + minutes : minutes;
    return `${hours}:${minutesStr} ${ampm}`;
}
export default SubscriptionList;
