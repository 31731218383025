import { useEffect, useState } from "react";
import "./ChatApp.css";
import { Dropdown, IconButton, PrimaryButton, Stack, TextField } from "@fluentui/react";
import { authProvider } from "../authProvider";
import ReactMarkdown from "react-markdown";

const apiRoot = window.location.origin;
const ChatApp = () => {
    const [messages, setMessages] = useState([]);
    const [input, setInput] = useState("");
    const [loading, setLoading] = useState(false);
    const [token, setToken] = useState(null);
    const [personality, setPersonality] = useState("loki");
    const contextMessages = 15;

    // Authenticate
    useEffect(() => {
        authProvider.getAccessToken().then((token) => {
            setToken(token.accessToken);
        });
    }, []);
    // chat messages have two fields: role and content. We want to display "{role}: {content}"
    const onSend = async () => {
        if (input.trim() === "") return;
        var newMessages = [...messages, { content: input, role: "user" }];
        setMessages(() => newMessages);
        const messagesToSend = newMessages.slice(-contextMessages);
        setLoading(true);
        var response = await fetch(apiRoot + "/api/hal", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify({personality: personality, messages: messagesToSend}),
        });
        if (response.ok) {
            response = await response.json();
            setMessages((newMsgs) => [...newMsgs, response]);
        }

        setInput("");
    };
    return (
        <div className="mainContainer">
            <div className="headingContainer">
                <img
                    src="/loki.png"
                    alt="A 3d cartoon rendering of the mischievous god Loki"
                />
                <div className="headingContainer">
                    <div className="heading">Nettech Loki AI</div>
                    <Dropdown label="Personality" options={[{ key: 'loki', text: 'Loki' },
                    {key: "hal", text: "HAL 9000"} ,
                    {key: "glados", text: "GLaDOS"},
                    {key: "thor", text: "Thor"},
                    {key: "bender", text: "Bender"},
                    {key: "professor farnsworth", text: "Professor Farnsworth"},
                    {key: "neo", text: "Neo"},
                    {key: "serious", text: "Serious"},
                    {key: "maurice", text:" Maurice Moss"}, 
                    {key: "roy", text: "Roy Trenneman"},
                    {key: "seinfeld", text: "Seinfeld"},
                    {key: "kramer", text: "Cosmo Kramer"}]}
                        selectedKey={personality}
                        onChange={(e, item) => setPersonality(item.key)}
                     />
                </div>
            </div>
            <div className="chatContainer">
                <div className="chatMessages">
                    {messages.map((msg, index) => (
                        <div
                            key={index}
                            className={
                                msg.role === "user"
                                    ? "userMessage"
                                    : "botMessage"
                            }
                        >
                            <span
                                style={{
                                    color:
                                        msg.role === "user" ? "red" : "green",
                                }}
                            >
                                {msg.role === "user" ? "You: " : "Loki: "}
                            </span>
                            <ReactMarkdown>{msg.content}</ReactMarkdown>   
                        </div>
                    ))}
                </div>
                <div className="chatPrompt">
                    <TextField
                        styles={{ root: { width: "600px" } }}
                        value={input}
                        onChange={(e) => setInput(e.target.value)}
                        onKeyDown={(e) => e.key === "Enter" && onSend()}
                        multiline={true}
                    />
                    <IconButton
                        iconProps={{ iconName: "send" }}
                        onClick={onSend}
                        text="Send"
                    />
                </div>
            </div>
        </div>
    );
};

export default ChatApp;
