import * as React from "react";
import * as ReactDOM from "react-dom";
import Home from './home';
import { OptionFrame } from './AbsenceRequestComponents/OptionFrame'
import { HpeBom } from './HpeBom/HpeBom';
import {PurchaseRequestForm} from "./PurchaseRequestComponents/PurchaseRequestForm"
import { AzureAD, AuthenticationState } from 'react-aad-msal'
import { authProvider } from './authProvider'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import { initializeIcons } from '@fluentui/font-icons-mdl2'
import { DefaultButton } from '@fluentui/react/lib/Button';
import { registerLicense } from '@syncfusion/ej2-base';
import { authConfig } from './AbsenceRequestComponents/ApiClientConfig';
import { CommandBar, ICommandBarItemProps } from '@fluentui/react/lib/CommandBar';
import { Client } from "./AbsenceRequestComponents/ApiClient";
import { Fabric } from '@fluentui/react/lib/Fabric';
import { MappingList } from "./SentinelMapping/MappingList";
import { AuvikMappingList } from "./AuvikMapping/AuvikMappingList";
import { DocumentAcceptance } from "./DocumentAcceptance/DocumentAcceptance";
import { AcceptanceManager } from "./DocumentAcceptance/AcceptanceManager";
import { EmployeeActions } from "./EmployeeForms/EmployeeActions";
import { AddEmployeeForm } from "./EmployeeForms/AddEmployeeForm";
import { TermEmployeeForm} from "./EmployeeForms/TermEmployeeForm";
import { CompanyTeamCleaner } from "./CompanyTeamCleanup/CompanyTeamCleaner";
import { SalesMasterHeatMapApp } from "./SalesHeatMap/SalesHeatMapApp";
import { HpSalesTrackerImporter } from "./HPSalesTrackerImporter/HpSalesTrackerImporter"
import { EstimateSelection } from "./EstimateActionPlan/EstimateSelection";
import { TaskEditor} from "./EstimateActionPlan/TaskEditor";
import { DattoMappingList } from "./DattoMapping/DattoMappingList";
import { DevPriorityList } from "./DevPriority/DevPriorityList";
import { RedGreenApp } from "./RedGreen/RedGreenApp";
import ChatApp from "./LokiAi/ChatApp";
import UsacMain from "./USACImport/UsacMain";
import BacklogComponent from "./DevBacklogApp/BacklogComponent";
import ReportList from "./ReportDocumentation/ReportList";

const DO_NOT_LOGIN = false;
initializeIcons();
registerLicense("ORg4AjUWIQA/Gnt2V1hhQlJAfV5AQmBIYVp/TGpJfl96cVxMZVVBJAtUQF1hSn5bd0RjUHtbcXJTQmZa")
const _commandBarItems: ICommandBarItemProps[] = [
    {
        key: 'ntTopImage',
        text: 'Networking Technologies',
        iconProps: { iconName: 'Upload' },
        onRender: () =>
            <img src="/NtTopHeader.png" alt="Networking Technologies cloud logo" />

    }
];
export class MyPage extends React.Component<any,any> {
    constructor(props: any) {
        super(props);

        var self = this;
        authProvider.getAccessToken().then(function (result) {
            var tkn = result.accessToken;
            //console.log('token: ' + tkn);
            if (tkn) {
                authConfig.token = tkn;
                var client = new Client();
                client.getUserInfo().then((res) => {
                    self.setState({

                        authenticated: true,
                        name: res.name
                    });

                });

            }
        });
      
    
    this.state = {
      
        authenticated: false,
        name: ""
        }
    }
    render() {

        if (this.state.authenticated) {


            return (
                <Fabric>
                    <Router>
                        <CommandBar
                            items={_commandBarItems}
                            farItems={this.getFarItems()}


                        />
                        <Switch>
                            <Route exact={true} path="/" render={() => (<Home />)} />
                            <Route path="/absencerequest" render={() => (<OptionFrame />)} />
                            <Route path="/egg" render={() => (<h1>&uArr; &uArr; &dArr; &dArr; &lArr; &rArr; &lArr; &rArr; b a start</h1>)} />
                            <Route path="/purchaserequest" render={() => (<PurchaseRequestForm />)} />
                            <Route path="/sentinelmapping" render={() => (<MappingList />)} />
                            <Route path="/auvikMapping" render={()=>(<AuvikMappingList/>)} />
                            <Route path="/hpebom" render={()=>(<HpeBom />)} />
                            <Route path="/acceptance/:id" render={()=>(<DocumentAcceptance />)} />
                            <Route path="/acceptancemanager" render={()=>(<AcceptanceManager />)}/>
                            <Route path="/employeeActions/Add" render={()=>(<AddEmployeeForm />)} />
                            <Route path="/employeeActions/Term" render={()=>(<TermEmployeeForm />)} />
                            <Route exact path="/employeeActions" render={()=>(<EmployeeActions />)} />
                            <Route path="/companyTeamCleanup"render={()=>(<CompanyTeamCleaner />)} />
                            <Route path="/ClientMasterHeatMap" render={() => (<SalesMasterHeatMapApp />)} />
                            <Route path="/HpSalesImporter" render={() => (<HpSalesTrackerImporter />) } />
                            <Route path="/ProjectActionPlan/:id" render={()=>(<TaskEditor />) }/>
                            <Route path="/ProjectActionPlan" render={()=>(<EstimateSelection />) }/>
                            <Route path="/DattoMapping" render={()=>(<DattoMappingList />)} />
                            <Route path="/DevPriority" render={()=>(<DevPriorityList />)} />
                            <Route path="/RedLightGreenLight" render={()=>(<RedGreenApp />)} />
                            <Route path="/usac" render={()=>(<UsacMain />)} />
                            <Route path="/devBacklog" render={()=>(<BacklogComponent />)} />
                            <Route path="/loki" render={()=>(<ChatApp />)}/>
                            <Route path="/docs" render={()=>(<ReportList />)}/>
                        </Switch>
                    </Router>
                </Fabric>
            );
        }
        else {
            return (
                <Fabric>
                    <CommandBar
                        items={_commandBarItems}
                        farItems={this.getFarItems()}

                    />
                    <h1>Please wait while you are authenticated...</h1>
                </Fabric>
            );
        }
    }

    private getFarItems = () => {
        return [
            {
                key: 'namedisplay',
                name: 'Sign Out',
                iconOnly: false,
                text: 'Hello ' + this.state.name


            },
            {
                key: 'logoff',
                name: 'Sign Out',
                iconOnly: false,
                onClick: () => {
                    authProvider.logout();
                    authConfig.token = '';
                }
            }
        ];
    };

}


ReactDOM.render(
    <AzureAD provider={authProvider} forceLogin={true}>
        {
            ({ login, logout, authenticationState, error, accountInfo }) => {
                console.log(authenticationState);
                switch (authenticationState) {
                    case AuthenticationState.Authenticated:
                        return (

                            <MyPage />
                        );
                    case AuthenticationState.Unauthenticated:
                        return (
                            <div>
                                {error && <p>An error occurred, please try again!</p>}
                                <DefaultButton onClick={login}>Login</DefaultButton>
                            </div>
                        );
                    case AuthenticationState.InProgress:
                        return (<div>
                            <p>Login in progress...</p></div>);
                    default:
                        return (<div>
                            <p>Please contact the developers. This shouldn't happen</p></div>);
                }
            }
        }

    </AzureAD>,
    document.getElementById('root'));

//ReactDOM.render(
//    <AzureAD provider={authProvider} forceLogin={true}>
//        <MyPage />
//    </AzureAD>, document.getElementById('root'));

//runWithAdal(authContext, () => {
//    ReactDOM.render(<MyPage />, document.getElementById('root'));
//}, DO_NOT_LOGIN);